import api from "../library/api";

/**
 * Fetches feature flags and its values
 * If user is not logged in, it returns generic values
 * If user is logged in, it returns specific values for that user
 *
 * @returns {Object} data - Response to the request
 */
export const fetchFeatureFlags = async () => {
  try {
    const { data: response } = await api.get("features/");
    return response;
  } catch (error) {
    throw error;
  }
};
