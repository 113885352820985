import React, { createContext, useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";

import { track, EVENTS } from "../library/analytics";
import { getUserId } from "../library/utils";

import { fetchProfile, fetchAccountCredit } from "../services";

import { identify } from "../library/analytics";
import ROLES from "../library/roles";

/*
Default context values will be used
if the context is loaded isolated
Eg: in unit test
*/
const defaultValues = {
  user: null,
  isLoggedIn: false,
  isAuthLoading: false,
  initialize: () => {},
  logout: () => {},
  permissions: {},
};

/**
 * Auth context
 *
 * @returns {Object} user - User object
 * @returns {Boolean} isLoggedIn - User authentication status
 * @returns {Function} initialize - To initialize the app with a token
 * @returns {Function} logout - To logout the user
 *
 * @example
 * const auth = useContext(AuthContext)
 * auth.initialize(tokens); //Initializes the authentication
 * auth.logout(); //Logs the user out
 * auth.isLoggedIn; //Returns true, false or null
 */
export const AuthContext = createContext(defaultValues);

/**
 * Context provider for Authentication
 *
 * @param  {} {children}
 */
export default function AuthContextProvider({ children }) {
  const localStorage = window.localStorage;
  const [userId, setUserId] = useState(null); // To store userId
  const [user, setUser] = useState(null);
  const {
    data,
    refetch: reloadProfileData,
    isFetching: isAuthLoading,
  } = useQuery(["profile", userId], fetchProfile, { enabled: !!userId }); // Calling the profile api

  const {
    data: creditData,
    refetch: refreshAcccountCredit,
    isFetching: accountCreditFetching,
  } = useQuery(["account-credit"], fetchAccountCredit, {
    enabled: false,
    cacheTime: 10000,
  });

  useEffect(() => {
    if (creditData?.data?.credit || creditData?.data?.credit === 0) {
      setUser((user) => {
        return {
          ...user,
          profile: {
            ...user?.profile,
            account: user?.profile?.account?.map((account) => ({
              ...account,
              available_credit: creditData?.data?.credit,
            })),
          },
        };
      });
    }
  }, [creditData]);

  /**
   * To know if user is logged in or not
   *
   * @param {Boolean} true - The user is logged in.
   * @param {Boolean} false - The user is not logged in.
   * @param {} null - Did not determine yet that the user is logged in or not
   * @default null
   */
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [permissions, setPermissions] = useState({});

  /**
   * If received tokens as params, use it to initiate the context
   * else, checks if token exists in the localstorage
   * if exists, decode the token and initiate the context
   *
   * @param {Object} newTokens
   */

  const initialize = useCallback(
    (newTokens = null) => {
      if (newTokens) {
        localStorage.setItem(
          process.env.REACT_APP_USER_TOKEN,
          JSON.stringify(newTokens)
        );
        setIsLoggedIn(true);
      } else {
        const storedTokens = localStorage.getItem(
          process.env.REACT_APP_USER_TOKEN
        );
        setIsLoggedIn(!!storedTokens);
      }

      setUserId(getUserId());
    },
    [localStorage]
  );

  /**
   * Logouts the user from the app
   */
  const logout = () => {
    setUserId(null);
    setIsLoggedIn(false);
    track(EVENTS.LOGGED_OUT);

    localStorage.removeItem(process.env.REACT_APP_USER_TOKEN);
    localStorage.removeItem(process.env.REACT_APP_GROUP_ID);
  };

  /**
   * Runs when the context is loaded for the first time
   */
  useEffect(() => {
    initialize();
  }, [initialize]);

  /**
   * Identify user whenever the fetch profile api is called
   */
  useEffect(() => {
    const userData = data?.data;
    if (userData) {
      identify(userData);

      setPermissions({
        canUploadClaimFile:
          userData?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN,
        canSubmitClaimForm:
          userData?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN ||
          userData?.profile?.role === ROLES.ROLE_USER,
        canFilterClaims:
          userData?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN ||
          userData?.profile?.role === ROLES.ROLE_REVIEWER,
        canViewSummary: userData?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN,
        canAssignToDoctor:
          userData?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN,
        canMarkAsResolved: [ROLES.ROLE_USER].includes(userData?.profile?.role),
        canViewDiseasesRecommendations:
          userData?.profile?.role === ROLES.ROLE_USER,
        canViewRevisedResult: userData?.profile?.role === ROLES.ROLE_USER,
        canAnalyze: userData?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN,
        canViewReviewDialog:
          userData?.profile?.role === ROLES.ROLE_USER ||
          userData?.profile?.role === ROLES.ROLE_REVIEWER,
        canMarkClaimfileAsSubmitted:
          userData?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN,
      });

      setUser(userData);
    }
  }, [data]);

  const PLAN_TYPES = {
    BASIC: "basic",
    PREMIUM: "premium",
  };

  return (
    <AuthContext.Provider
      value={{
        user: user,
        account: user?.profile?.account ? user?.profile?.account[0] : null,
        isLoggedIn,
        initialize,
        isAuthLoading,
        reloadProfileData,
        logout,
        permissions,
        refreshAcccountCredit,
        accountCreditFetching,
        PLAN_TYPES,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
