import React, { useContext } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { Grid, Button, Typography, Container, Divider } from "@mui/material";
import { Box } from "@mui/system";

import { NotFoundImageContainer, GotoButton } from "./components";

import { darkGrey, pickledBlueWoodGrey, catskillWhite } from "../library/theme";

import logoImage from "../images/glance-logo.svg";
import { arrowLeftIcon } from "../images/icons";
import NotFoundImage from "../images/notFound-image.png";
import { AuthContext } from "../contexts";

import { Title } from "../common";

export default function NotFoundPage() {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const renderNotFoundImage = () => {
    return (
      <div>
        <NotFoundImageContainer>
          <Box
            component="img"
            src={NotFoundImage}
            sx={{ width: "555px", height: "555px" }}
          />
        </NotFoundImageContainer>
      </div>
    );
  };

  return (
    <>
      <Title page="404 page not found" />
      <Container maxWidth="xl">
        <Box
          component={Grid}
          sx={{ minHeight: "100vh" }}
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid container item xs={12} md={6} lg={5}>
            <Grid container item alignItems="center" sx={{ pb: 15 }}>
              <Grid item sm={3}>
                <Link to="/">
                  <SVG src={logoImage} />
                </Link>
              </Grid>
            </Grid>

            <Typography
              variant="handwriting1"
              sx={{ fontWeight: "bold", mb: 5, fontSize: 82 }}
            >
              Ooooooops!
            </Typography>

            <Typography
              variant="h1"
              sx={{
                fontWeight: "normal",
                color: pickledBlueWoodGrey,
                fontSize: "35px",
                mb: 2,
              }}
            >
              This page no longer exists
            </Typography>

            <Typography variant="body2" sx={{ mb: 15, color: darkGrey, pl: 1 }}>
              It might be removed, changed name or temporarily unavailable.
            </Typography>

            <Grid xs={12} container alignItems="center" sx={{ mb: 10 }}>
              <Divider
                width="100%"
                sx={{ mb: 5, borderColor: catskillWhite }}
              />

              <Grid xs={2} item>
                <Button
                  startIcon={
                    <Box
                      component={SVG}
                      src={arrowLeftIcon}
                      sx={{ color: "common.black" }}
                    />
                  }
                  onClick={history.goBack}
                >
                  <Typography variant="body2" sx={{ color: darkGrey }}>
                    Back
                  </Typography>
                </Button>
              </Grid>
              <Box
                component="div"
                sx={{ borderRight: `1px solid ${darkGrey}`, height: "20px" }}
              />
              <Grid item sx={{ pl: 2 }}>
                <GotoButton component={Link} to={"/support"}>
                  <Typography sx={{ fontFamily: "Caveat", fontSize: 18 }}>
                    Need help!
                  </Typography>
                </GotoButton>
              </Grid>
              <Grid item xs={3}>
                <GotoButton
                  component={Link}
                  to={`/support/send-message?email=${user?.email}`}
                >
                  <Typography>Send Message</Typography>
                </GotoButton>
              </Grid>
              <Box
                component="div"
                sx={{ borderRight: `1px solid ${darkGrey}`, height: "20px" }}
              />
              <Grid item xs={3} sx={{ pl: 2 }}>
                <GotoButton
                  component="a"
                  href="https://wa.me/966505655750"
                  target="_blank"
                >
                  <Typography>Live chat</Typography>
                </GotoButton>
              </Grid>
              <Divider
                width="100%"
                sx={{ mt: 5, borderColor: catskillWhite }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            {renderNotFoundImage()}
          </Grid>
        </Box>
      </Container>
    </>
  );
}
