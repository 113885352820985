import React, { Component } from "react";

import { Typography } from "@mui/material";

import ErrorPage from "./ErrorPage";

import { Title } from "../common";

export default class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }
  render() {
    const { error, info } = this.state;
    // Handle error if there is an error
    if (error) {
      return (
        <div>
          <Title page="Error Boundary page" />
          <ErrorPage />
          <Typography variant="body1" component="details">
            {error && error.toString()}
            <br />
            {info.componentStack}
          </Typography>
        </div>
      );
    }

    // Else return the app
    return this.props.children;
  }
}
