import React, { useEffect } from "react";
import RouterComponent from "./Router";
import { initialize, mixpanelInitialize } from "./library/analytics";
import "react-datepicker/dist/react-datepicker.css";

import ChatWidget from "./support/ChatWidget";

function App() {
  useEffect(() => {
    // Initialize analytics tracking
    // when the app is initiated
    initialize();
    mixpanelInitialize();
  }, []);

  return (
    <>
      <RouterComponent />
      <ChatWidget />
    </>
  );
}

export default App;
