import { createTheme } from "@mui/material/styles";

import "normalize.css";
import "@fontsource/poppins/index.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/300.css";
import "@fontsource/caveat";

const theme = createTheme({});

export const lavendarBlush = "#FFF1F2";
export const catskillWhite = "#F1F5F9";
export const lightGreen = "#F0FDF4";
export const lightGrey = "#F8FAFC";
export const honeyDew = "#ECFDF5";
export const servicesGreen = "#ECF4F4";
export const localSearchGrey = "#E2E8F0";
export const emptyStateGrey = "#CBD5E1";
export const grey = "#94A3B8";
export const darkGrey = "#64748B";
export const darkGreyColor = "#475569";
export const pickledBlueWoodGrey = "#334155";
export const blackGrey = "#0F172A";
export const lightBlueColor = "#DBEAFE";
export const babyBlueEyes = "#99CFFF";
export const testClaimBorderColor = "#69AAEB";
export const lighterBlue = "#3697F0";
export const lightBlue = "#0075E1";
export const blue = "#0859E4";
export const lightYellow = "#FEFCE8";
export const yellowColor = "#FEF08A";
export const analyzeYellow = "#FACC15";
export const claimsYellow = "#FACC15";
export const goldenColor = "#EAB308";
export const golden = "#CA8A04";
export const lightRed = "#FB7185";
export const red = "#F43F5E";
export const reportRed = "#E11D48";
export const claimStatusGreen = "#BBF7D0";
export const testClaimGreen = "#4ADE80";
export const progressGreenLight = "#00CE6B";
export const uploadGreen = "#22C55E";
export const greenColor = "#16A34A";
export const primaryGradient = `linear-gradient(180deg, ${lightBlue}, ${blue})`;
export const onBoardingGradient1 = `linear-gradient(180deg, ${lavendarBlush}, ${honeyDew})`;
export const onBoardingGradient2 = "linear-gradient(180deg, #EEF2FF, #F7FEE7)";
export const whiteToGreyGradient = `linear-gradient(180deg, ${theme.palette.common.white}, ${catskillWhite})`;

export function transparentize(color, opacity) {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export default createTheme({
  palette: {
    primary: {
      main: blue,
    },
    secondary: {
      main: darkGrey,
    },
  },
  typography: {
    fontFamily: "Poppins, Roboto, Arial",
    handwriting1: {
      color: red,
      fontFamily: "Caveat",
      fontSize: theme.typography.pxToRem(42),
    },
    handwriting2: {
      color: red,
      fontFamily: "Caveat",
      fontSize: theme.typography.pxToRem(32),
    },
    handwriting3: {
      color: red,
      fontFamily: "Caveat",
      fontSize: theme.typography.pxToRem(22),
    },
    handwriting4: {
      color: red,
      fontFamily: "Caveat",
      fontSize: theme.typography.pxToRem(16),
    },
    h1: {
      fontSize: theme.typography.pxToRem(42),
      fontWeight: 700,
    },
    h2: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 700,
    },
    h3: {
      fontSize: theme.typography.pxToRem(22),
      fontWeight: 700,
    },
    h4: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 700,
    },
    body2: {
      color: grey,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 15,

          "&.Mui-focused .MuiInputAdornment-root": {
            color: blue,
          },
        },
        notchedOutline: {
          borderColor: grey,
          padding: "0 16px",
        },
        input: {
          padding: "20px 22px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 15,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: `translate(22px, 20px) scale(1)`,

          "&.Mui-focused, &.MuiFormLabel-filled": {
            transform: `translate(22px, -9px) scale(0.75)`,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: grey,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "cta" },
          style: {
            background: primaryGradient,
            color: theme.palette.common.white,
            minWidth: 300,
            display: "flex",
            justifyContent: "space-between",
            boxShadow: "none",
            fontWeight: 700,
            padding: "20px 33px",
            "&:hover": {
              boxShadow: theme.shadows[4],
            },
            "&:disabled": {
              background: grey,
              cursor: "not-allowed",
              color: theme.palette.common.white,
              "&:hover": {
                boxShadow: "none",
              },
            },
          },
        },
        {
          props: { variant: "white" },
          style: {
            background: theme.palette.common.white,
            color: darkGrey,
            minWidth: 185,
            display: "flex",
            justifyContent: "space-between",
            boxShadow: "none",
            fontWeight: 700,
            padding: "10px 18px",
            "&:hover": {
              background: `linear-gradient(180deg, ${lightBlue} 0%, ${blue} 100%)`,
              boxShadow: "0px 4px 8px rgba(8, 89, 228, 0.35)",
              color: theme.palette.common.white,
            },
            "&:disabled": {
              background: grey,
              cursor: "not-allowed",
              color: theme.palette.common.white,
              "&:hover": {
                boxShadow: "none",
              },
            },
          },
        },
        {
          props: { variant: "primary" },
          style: {
            background: primaryGradient,
            color: theme.palette.common.white,
            minWidth: 160,
            boxShadow: "none",
            fontWeight: 700,
            padding: "8px 32px",
            display: "inline-flex",
            "&:hover": {
              boxShadow: theme.shadows[4],
            },
            "&:disabled": {
              background: grey,
              cursor: "not-allowed",
              color: theme.palette.common.white,
              "&:hover": {
                boxShadow: "none",
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "none",
          fontsize: 16,
          borderRadius: 100,
        },
        sizeLarge: {
          padding: "16px 32px",
        },
        sizeMedium: {
          padding: "8px 24px",
        },
        sizeSmall: {
          padding: "8px 16px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          marginTop: theme.spacing(2),
          borderRadius: 25,
          boxShadow: "none",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          "&.MuiPaper-root": {
            borderRadius: 15,
            paddingTop: 8,
            paddingBottom: 8,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
  },
});
