import React, { createContext, useEffect, useState } from "react";

import { omit } from "lodash";
import { track, EVENTS } from "../library/analytics";

import { Snackbar } from "@mui/material";

const defaultValues = {};

export const SummaryContext = createContext(defaultValues);

export default function SummaryContextProvider({ children }) {
  const [summary, setSummary] = useState(defaultValues);
  const [count, setSummaryCount] = useState(0);
  const [copyText, setCopyText] = useState(0);
  const [openCopyFeedback, setOpenCopyFeedback] = useState(false);
  const types = {
    DIAGNOSIS: "diagnosis",
    MEDICATION: "medication",
    CLICNICAL_FINDING: "clinical_finding",
    INVESTIGATION: "investigation",
  };
  const typeError = (type) => {
    console.error(
      `Unknown summary type: ${type}, Use any of the following: `,
      Object.keys(types)
    );
    return false;
  };

  const handleCloseCopyFeedback = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenCopyFeedback(false);
  };

  // Initializes the summary data.
  useEffect(() => {
    const summaryData = window.localStorage.getItem(
      process.env.REACT_APP_SUMMARY
    );
    const _summary = JSON.parse(summaryData);

    // calculate the count
    let _count = 0;
    if (_summary) {
      Object.keys(_summary).forEach((type) => {
        if (_summary[type]) {
          _count += Object.keys(_summary[type]).length;
        }
      });
    }

    setSummaryCount(_count);
    setSummary(_summary);
  }, []);

  // updates local storage if summary data changes
  useEffect(() => {
    // calculate the count
    let _count = 0;
    summary &&
      Object.keys(summary).forEach((type) => {
        if (summary[type]) {
          _count += Object.keys(summary[type]).length;
        }
      });
    setSummaryCount(_count);

    // store the summary on local storage
    window.localStorage.setItem(
      process.env.REACT_APP_SUMMARY,
      JSON.stringify(summary)
    );
  }, [summary]);

  // Select items
  const select = (item, type) => {
    if (Object.values(types).includes(type)) {
      const code = type === types.MEDICATION ? "RegisterNumber" : "code";
      const _summary = {
        ...summary,
        [type]: { ...summary?.[type], [item[code]]: item },
      };

      setSummary(_summary);
      track(EVENTS.SUMMARY_SELECT, { code: item[code], type: type });
      return true;
    } else {
      return typeError(type);
    }
  };

  const getRandomIndex = (synonymItem) => {
    let currentIndex = 0;
    if (synonymItem?.context?.synonyms?.length > 1) {
      while (
        synonymItem?.context?.synonyms[currentIndex] === synonymItem?.name
      ) {
        currentIndex = Math.floor(
          Math.random() * synonymItem?.context?.synonyms?.length
        );
      }
    }

    return currentIndex;
  };

  const refreshSelectedSynonyms = (selectedSymptom) => {
    const code = "code";
    const selectedSymptomIndex = getRandomIndex(selectedSymptom);

    const _summary = {
      ...summary,
      [types.CLICNICAL_FINDING]: {
        ...summary[types.CLICNICAL_FINDING],
        [selectedSymptom[code]]: {
          ...selectedSymptom,
          name: selectedSymptom?.context?.synonyms[selectedSymptomIndex],
        },
      },
    };
    setSummary(_summary);
  };

  const refreshAllSymptoms = () => {
    let _summary = { ...summary };
    const code = "code";

    if (types.CLICNICAL_FINDING in summary) {
      Object.keys(summary?.[types.CLICNICAL_FINDING])?.forEach(
        (listItemKey) => {
          const selectedSymptomIndex = getRandomIndex(
            summary[types.CLICNICAL_FINDING][listItemKey]
          );

          _summary = {
            ..._summary,
            [types.CLICNICAL_FINDING]: {
              ..._summary[types.CLICNICAL_FINDING],
              [_summary[types.CLICNICAL_FINDING][listItemKey][code]]: {
                ..._summary[types.CLICNICAL_FINDING][listItemKey],
                name: _summary[types.CLICNICAL_FINDING][listItemKey]?.context
                  ?.synonyms[selectedSymptomIndex],
              },
            },
          };
        }
      );

      setSummary(_summary);
    }
  };

  // Unselect items
  const unSelect = (item, type) => {
    if (Object.values(types).includes(type)) {
      const code = type === types.MEDICATION ? "RegisterNumber" : "code";
      if (type in summary && item[code] in summary[type]) {
        setSummary({
          ...summary,
          [type]: omit(summary[type], item[code]),
        });
        track(EVENTS.SUMMARY_UNSELECT, { code: item[code], type: type });
      }
      return true;
    } else {
      return typeError(type);
    }
  };

  // Check if item is selected or not
  const isSelected = (item, type) => {
    if (Object.values(types).includes(type)) {
      const code = type === types.MEDICATION ? "RegisterNumber" : "code";
      return (
        summary &&
        type in summary &&
        item &&
        code in item &&
        item[code] in summary[type]
      );
    } else {
      return typeError(type);
    }
  };

  // Clear all
  const clear = () => {
    setSummary(defaultValues);
    track(EVENTS.SUMMARY_CLEAR);
  };

  // Copy diagnosis
  const copy = (item, type) => {
    if (Object.values(types).includes(type)) {
      const target =
        type === types.DIAGNOSIS
          ? "code"
          : type === types.MEDICATION
          ? "TradeName"
          : "name";
      navigator.clipboard.writeText(item[target]);
      setCopyText(item[target]);
      setOpenCopyFeedback(true);
      track(EVENTS.CDS_COPY, { [target]: item[target], type: type });
      return item[type];
    } else {
      if (typeof type == "string" && item[type]) {
        navigator.clipboard.writeText(item[type]);
        setCopyText(item[type]);
        setOpenCopyFeedback(true);
        track(EVENTS.CDS_COPY, { [type]: item[type], type: type });
        return item[type];
      } else {
        return typeError(type);
      }
    }
  };

  // Copy all clinical findings in a single string
  const copyFindings = () => {
    if (types.CLICNICAL_FINDING in summary) {
      const clinicalFindingNames = Object.values(
        summary[types.CLICNICAL_FINDING]
      ).reduce((acc, finding) => {
        return [...acc, finding.name];
      }, []);
      const clinicalFindingNamesJoined = clinicalFindingNames.join(", ");
      navigator.clipboard.writeText(clinicalFindingNamesJoined);
      setCopyText(clinicalFindingNamesJoined);
      setOpenCopyFeedback(true);
      track(EVENTS.CDS_COPY_FINDINGS, {
        name: clinicalFindingNamesJoined,
      });
      return clinicalFindingNamesJoined;
    }
  };

  const isEmpty = (type) => {
    if (type) {
      if (Object.values(types).includes(type)) {
        return !(summary[type] && Object.keys(summary[type]).length);
      } else {
        return typeError(type);
      }
    } else {
      return !count;
    }
  };

  return (
    <SummaryContext.Provider
      value={{
        summary, // summary data
        types, // supported data types
        isSelected, // Check if item is selected or not
        select, // select an item, accepts (item, type)
        unSelect, // unselect an item, accepts (item, type)
        clear, // clear all the summary
        copy, // copy an item, accepts (item, type)
        copyFindings, // copy all the findings
        count, // Return count of
        refreshSelectedSynonyms, // refresh current selected synonyms
        refreshAllSymptoms, // refresh All Symptoms
        isEmpty, // Returns if summary or a type of summary is empty, accepts (type)
      }}
    >
      {children}
      <Snackbar
        open={openCopyFeedback}
        autoHideDuration={6000}
        onClose={handleCloseCopyFeedback}
        message={
          <>
            Copied <strong>{copyText}</strong>
          </>
        }
      />
    </SummaryContext.Provider>
  );
}
