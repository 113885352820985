import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { FullStoryAPI } from "react-fullstory";
import platform from "platform";
import mixpanel from "mixpanel-browser";

import { getUserId, getAccountId } from "./utils";

const userId = getUserId();
const accountId = getAccountId();

// Define the configurations
const tagManagerConfigs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
  dataLayer: {
    userId: userId,
    userProject: "app",
    preview: process.env.NODE_ENV,
  },
};

const googleAnalyticsCOnfigs = {
  debug: process.env.NODE_ENV !== "production",
  titleCase: false,
  testMode: process.env.NODE_ENV !== "production",
  gaOptions: {
    userId: userId,
  },
};

// Initialize all the trackings
export const initialize = () => {
  if (process.env.NODE_ENV === "production") {
    // Initialize Google Tag Manager
    TagManager.initialize(tagManagerConfigs);

    // Initialize Google analytics tracking
    ReactGA.initialize(process.env.REACT_APP_GA_KEY, googleAnalyticsCOnfigs);

    // Initialize page reload tracking
  } else {
    window.analytics?.debug(true);
  }
};

export const mixpanelInitialize = () => {
  if (
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_MIXPANEL_KEY
  ) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
  } else if (process.env.REACT_APP_MIXPANEL_KEY) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: true });
  }
};

/**
 * Identify the user
 *
 * Inform and update the tracking clients
 * (fullstory) of user details
 *
 * @param  {object} user - User details
 */
export const identify = (user) => {
  if (user?.id) {
    const { profile, user_permissions, groups, ...userData } = user;
    if (user.hasOwnProperty("profile")) {
      const { id, account, saved_diseases, viewed_diseases, ...profileData } =
        profile;

      if (account && account.length) {
        const { city, ...accountData } = account[0];
        accountData.city = city.name;
        user = { ...userData, ...profileData };

        localStorage.setItem(
          process.env.REACT_APP_GROUP_ID,
          JSON.stringify(accountData.id)
        );
      }
    }

    // fullstory identification
    FullStoryAPI("identify", user.id, user);
  } else {
    // For debugging purpose
    console.groupCollapsed(
      "%cIdentify",
      "background-color: orange; color: white; border-radius: 3px 3px 3px 3px; display: inline-block; font-weight: bold; padding: 3px 7px 3px 7px;",
      user.id
    );
    console.log("user: ", user);
    console.groupEnd();
  }
};

/**
 * Track events
 *
 * Collect user events and send it to mixpanel
 *
 * @param  {String} event - Event name
 * @param  {Object} properties - Event properties
 */

export const track = (event, properties = {}, ...others) => {
  const userId = getUserId();

  if (process.env.REACT_APP_MIXPANEL_KEY) {
    mixpanel.track(event, {
      userId: userId,
      distinct_id: userId,
      ...properties,
      context: { groupId: accountId, ...platform },
      ...others,
    });
  }

  if (process.env.NODE_ENV === "development") {
    console.log("mixpanel", event, {
      userId: userId,
      distinct_id: userId,
      ...properties,
      context: { groupId: accountId, ...platform },
      ...others,
    });
  }
};

export const pageTrack = (properties = {}, ...others) => {
  const userId = getUserId();

  if (process.env.REACT_APP_MIXPANEL_KEY) {
    mixpanel.track(EVENTS.PAGE_TRACKING, {
      userId: userId,
      distinct_id: userId,
      title: document.title,
      ...properties,
      context: { groupId: accountId, ...platform },
      ...others,
    });
  }

  if (process.env.NODE_ENV === "development") {
    console.log("mixpanel", EVENTS.PAGE_TRACKING, {
      userId: userId,
      distinct_id: userId,
      title: document.title,
      ...properties,
      context: { groupId: accountId, ...platform },
      ...others,
    });
  }
};

export const EVENTS = {
  LOGGED_IN: "User:Logged In",
  LOGGED_OUT: "User:Logged Out",
  PAGE_TRACKING: "User: Visited page",
  CLAIM_UPLOADED: "Claim file:Uploaded",
  CLAIM_UPLOAD_OPENED: "Claim file:Opened upload dialog",
  CLAIM_UPLOAD_CLOSED: "Claim file:Closed upload dialog",
  CLAIM_ANALYSIS_STARTED: "Claim file:Analysis started",
  CLAIM_ANALYSIS_FAILED: "Claim file:Analysis failed with error",
  CLAIM_PREVIEW_OPEN: "Claim file:Preview opened",
  CLAIM_REVIEW_OPEN: "Claim file:Review opened",
  CLAIM_REVIEW_CODE_COPIED: "Claim file: Claim code copied from review dialog",
  CLAIM_PREVIEW_CLOSED: "Claim file:Preview closed",
  CLAIM_REUPLOADED: "Claim file:Re-uploaded",
  CLAIM_OPENED_MARKED_SUBMITTED_DIALOG:
    "Claim file:Opened Marked as submitted dialog",
  CLAIM_CLOSED_MARKED_SUBMITTED_DIALOG:
    "Claim file:Closed Marked as submitted dialog",
  CLAIM_MARKED_SUBMITTED: "Claim file:Marked as submitted",
  CLAIM_UNMARKED_SUBMITTED: "Claim file:Un Marked as submitted",
  CLAIM_MULTIPLE_ASSIGNED: "Claims:Assigned multiple claims",
  CLAIM_ASSIGNED: "Claims:Assigned claim",
  CLAIM_MULTIPLE_UNASSIGNED: "Claims:Unassigned multiple claims",
  CLAIM_UNASSIGNED: "Claims:Unassigned claim",
  CLAIM_RESOLVED: "Claims:Resolved claim",
  CLAIM_UNRESOLVED: "Claims:Un Resolved claim",
  CLAIM_REANALYSIS_STARTED_WITH_RECOMMENDATIONS:
    "Claims:Re analysing corrected claim",
  CLAIM_DIAGNOSIS_RECOMMENDATION_CLOSED: "Claims:Recommendation Dialog closed",
  CLAIM_DIAGNOSIS_RECOMMENDATION_OPENED: "Claims:Recommendation Dialog opened",
  CLAIM_RECOMMENDED_DISEASE_SELECTED: "Claims:Recommended disease selected",
  CLAIM_RECOMMENDED_DISEASE_UNSELECTED:
    "Claims:Recommended disease un selected",
  CLAIM_OPEN_SINGLE_CLAIM_DIALOG: "Claims:Open single claim dialog",
  CLAIM_CLOSE_SINGLE_CLAIM_DIALOG: "Claims:Close single claim dialog",
  CLAIM_ANALYSE_SINGLE_CLAIM: "Claims:Analyse single claim",
  ICD10_VIEW_BILLABLE_CODES: "CDS:Opened disease billable codes",
  SUMMARY_SELECT: "CDS:Added to summary",
  SUMMARY_UNSELECT: "CDS:Removed from summary",
  SUMMARY_CLEAR: "CDS:Summary cleared",
  CDS_COPY: "CDS:Copied",
  CDS_COPY_DIAGNOSIS: "CDS:Copied diagnosis",
  CDS_COPY_FINDINGS: "CDS:Copied clinical findings",
  CDS_COPY_DRUG: "CDS:Copied medications",
  CDS_GENERATE_CLINICAL_NOTE: "CDS:Generated clinical note",
  CDS_COPY_CLINICAL_NOTE: "CDS:Copied generated clinical note",
  CDS_FAVORITED: "CDS:Favorited diagnosis",
  CDS_UNFAVORITED: "CDS:Un Favorited diagnosis",
  CDS_SEARCHING: "CDS:Searching",
  CDS_SEARCHED: "CDS:Searched",
  EDIT_SINGLE_CLAIM: "Claims:Edit single claim clicked",
};
