import React, { createContext, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { fetchDiseaseDrugs } from "../services";

/*
Default context values will be used
if the context is loaded isolated
Eg: in unit test
*/
const defaultValues = {
  diseases: [],
};

export const FetchDiseaseDrugsContext = createContext(defaultValues);

export default function FetchDiseaseDrugsContextProvider({ children }) {
  const [code, setCode] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [pageNumber, setPageNumber] = useState();
  const [searchTermError, setSearchTermError] = useState();
  const {
    data: drugs,
    isSuccess: isDrugFetchSuccess,
    isLoading: isDrugFetchLoading,
  } = useQuery(
    ["disease-drugs", code, searchTerm, pageNumber],
    () => fetchDiseaseDrugs(code, searchTerm, pageNumber),
    {
      enabled: !!code && !!pageNumber,
      onError: (error) => {
        setSearchTermError(error);
      },

      onSuccess: () => {
        setSearchTermError("");
      },
    }
  );

  const setDiseaseCode = useCallback((diseaseCode) => {
    if (diseaseCode) {
      setCode(diseaseCode);
    }
  }, []);

  const setSearchDetails = useCallback((searchQuery, page) => {
    setSearchTerm(searchQuery);
    setPageNumber(page || 1);
  }, []);

  return (
    <FetchDiseaseDrugsContext.Provider
      value={{
        drugs,
        setDiseaseCode,
        setSearchDetails,
        isDrugFetchSuccess,
        isDrugFetchLoading,
        searchTermError,
      }}
    >
      {children}
    </FetchDiseaseDrugsContext.Provider>
  );
}
