import api from "../library/api";
import { EVENTS, track } from "../library/analytics";

/**
 * return a single disease
 * @param  {String} code
 *
 * @returns {String} data - Response data if request was success
 */
export const fetchDisease = async (code) => {
  const { data } = await api.get(`search/disease/${code}/`);
  return data;
};

/**
 * Returns a drugs related to given disease code
 *
 * @returns {String} data - Response data if request was success
 * @param diseaseCode
 */
export const branchSearch = async (diseaseCode) => {
  const { data } = await api.get(`search/disease/${diseaseCode}/branches/`);
  return data;
};

/**
 * Returns list of branches related to a disease code
 * @param  {String} code
 *
 * @param searchTerm
 * @param limit
 * @param offset
 * @returns {String} data - Response data if request was success
 */
export const fetchDiseaseDrugs = async (code, searchTerm = "", page = 1) => {
  let url = `search/disease/${code}/drugs/?q=${searchTerm}&page=${page}`;
  const { data } = await api.get(url);
  return data;
};

/**
 * Returns drug details related to a disease and drug code
 * @param  {String} drugCode
 *
 * @returns {String} data - Response data if request was success
 */
export const fetchDiseaseDrugDetails = async (diseaseCode, drugCode) => {
  const { data } = await api.get(
    `/search/disease/${diseaseCode}/drugs/${drugCode}/`
  );
  return data;
};

/**
 * Returns symptoms related to a disease
 *
 * @returns {String} data - Response data if request was success
 * @param diseaseCode
 */
export const fetchDiseaseSymptoms = async (diseaseCode) => {
  const { data } = await api.get(`/search/disease/${diseaseCode}/symptoms/`);
  return data;
};

/**
 * Returns synonyms related to symptoms
 *
 * @returns {String} data - Response data if request was success
 * @param symptomCode
 */
export const fetchSymptomSynonyms = async (symptomCode) => {
  const { data } = await api.get(`/search/symptoms/${symptomCode}/synonyms/`);
  return data;
};

/**
 * Returns Tests related to a disease
 *
 * @returns {String} data - Response data if request was success
 * @param diseaseCode
 */
export const fetchServices = async (diseaseCode) => {
  const { data } = await api.get(
    `/search/disease/${diseaseCode}/investigations/`
  );
  return data;
};

/**
 * Returns Test details related to a disease
 *
 * @param diseaseCode
 * @param  {String} testCode
 * @returns {String} data - Response data if request was success
 */
export const fetchTestDetails = async (diseaseCode, testCode) => {
  const { data } = await api.get(
    `/search/disease/${diseaseCode}/investigations/${testCode}/`
  );
  return data;
};

/**
 * return search suggestions related to given input text
 * @param  {String} text
 *
 * @returns {String} data - Response data if request was success
 */
export const autoSuggestKeywords = async (text) => {
  const { data } = await api.get(`search/autosuggest/?text=${text}`);
  track(EVENTS.CDS_SEARCHING, { text });
  return data;
};

/**
 * return search autocomplete related to given input text
 * @param  {String} text
 *
 * @returns {String} data - Response data if request was success
 */
export const autoCompleteIcdCode = async (text) => {
  const { data } = await api.get(`search/autocomplete/icd10am/?q=${text}`);
  return data;
};

/**
 * return search autocomplete related to given input text
 * @param  {String} text
 *
 * @returns {String} data - Response data if request was success
 */
export const autoCompleteService = async (serviceType, text) => {
  const { data } = await api.get(
    `search/autocomplete/${serviceType}/?q=${text}`
  );
  return data;
};

/**
 * Returns symptoms filter options
 *
 * @returns {String} data - Response data if request was success
 */

export const symptomsFilterList = async (
  diseaseTerm,
  filterTerm = "",
  offset = 0
) => {
  const { data } = await api.get(
    `search/filters/indications/?disease_term=${diseaseTerm}&filter_term=${filterTerm}&limit=20&offset=${offset}`
  );
  return data;
};

/**
 * Returns symptoms filter options
 *
 * @returns {String} data - Response data if request was success
 */

export const medicationsFilterList = async (
  diseaseTerm,
  filterTerm = "",
  offset = 0
) => {
  const { data } = await api.get(
    `search/filters/medications/?disease_term=${diseaseTerm}&filter_term=${filterTerm}&limit=20&offset=${offset}`
  );
  return data;
};

/**
 * Returns symptoms filter options
 *
 * @returns {String} data - Response data if request was success
 */

export const investigationsFilterList = async (
  diseaseTerm,
  filterTerm = "",
  offset = 0
) => {
  const { data } = await api.get(
    `search/filters/investigations/?disease_term=${diseaseTerm}&filter_term=${filterTerm}&limit=20&offset=${offset}`
  );
  return data;
};

/**
 * return search results related to given input text
 * @param  {String} text
 *
 * @param investigations
 * @param medications
 * @param indications
 * @param limit
 * @param offset
 * @returns {String} data - Response data if request was success
 */
export const getSearchResult = async (
  text,
  investigations = "",
  medications = "",
  indications = "",
  limit = "",
  offset = ""
) => {
  const { data } = await api.get(
    `search/result/?text=${text}&limit=${limit}&offset=${offset}`
  );
  track(EVENTS.CDS_SEARCHED, { text });
  return data;
};

export const getDiseasesOfService = async (code, offset, limit = 20) => {
  const { data } = await api.get(
    `search/services/${code}/diseases/?limit=${limit}&offset=${offset}`
  );
  return data;
};

export const getDiseasesOfMedications = async (code, offset, limit = 20) => {
  const { data } = await api.get(
    `search/drugs/${code}/diseases/?limit=${limit}&offset=${offset}`
  );
  return data;
};

export const getClinicalNotes = async (
  diagnoses,
  medications,
  investigations,
  findings,
  note
) => {
  const { data } = await api.post(`search/generate-clinical-note/`, {
    diagnoses,
    medications,
    investigations,
    findings,
    note,
  });
  return data;
};
