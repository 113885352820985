import { useLocation } from "react-router";

/**
 * A custom hook that will return query string
 * params of current page url
 *
 * @example
 * const query = useUrlQuery();
 * query.get("param")
 */
export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}
