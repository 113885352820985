import React, { useEffect, useState } from "react";
import lottie from "lottie-web";
import { Box, Container, Typography } from "@mui/material";

import loadingAnimation from "./loadingAnimation.json";

function SpinnerComponent({ label = null, wrapper = true, size = "large" }) {
  const [sizeAttrs, setSizeAttrs] = useState({
    height: 200,
    width: 200,
  });
  const [wrapperPaddingSize, setWrapperPaddingSize] = useState(15)

  useEffect(() => {
    if (size === "small") {
      setSizeAttrs({
        height: 100,
        width: 100
      })
      setWrapperPaddingSize(1)
    } else if (size === "medium") {
      setSizeAttrs({
        height: 150,
        width: 150
      })
      setWrapperPaddingSize(10)
    }
  }, [size])
  

  useEffect(() => {
    const elm = document.querySelector("#loader-animation-container");
    if (elm) {
      lottie.loadAnimation({
        container: elm,
        animationData: loadingAnimation,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });
    }
  }, []);

  if (wrapper) {
    return (
      <Box
        component={Container}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{ p: wrapperPaddingSize }}
      >
        <Box
          id="loader-animation-container"
          sx={{ ...sizeAttrs, m: "0 auto" }}
        />
        {label && <Typography align="center">{label}</Typography>}
      </Box>
    );
  }

  return (
    <Box
      id="loader-animation-container"
      sx={{ ...sizeAttrs, m: "0 auto" }}
    />
  );
}

export default SpinnerComponent;
