import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { pageTrack } from "../library/analytics";

function Title({ page }) {
  useEffect(() => {
    if (page) {
      pageTrack();
    }
  }, [page]);

  return (
    <Helmet titleTemplate="%s | Glance claims">
      <title>{page}</title>
    </Helmet>
  );
}

export default Title;
