import React, { useContext } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

import { Grid, Divider, Box } from "@mui/material";

import { NavBarComponent, CreditContainer } from "./components";
import UserPopup from "./UserPopup";
import Navigation from "./Navigation";

import ROLES from "../../library/roles";
import { catskillWhite, blue } from "../../library/theme";

// Images
import logoImage from "../../images/glance-logo.svg";

// Context
import { AuthContext } from "../../contexts";

function NavSideBarContainer({ children }) {
  const { account, user } = useContext(AuthContext);

  return (
    <Grid container>
      <Grid item xs={12} sm={3} md={2} sx={{ position: "relative" }}>
        <NavBarComponent container>
          <Grid item>
            <Link to="/">
              <Box component={SVG} src={logoImage} sx={{ color: blue }} />
            </Link>
            <Navigation />
          </Grid>
          <Grid item>
            <Divider />
            {/* Show credit points */}
            {user?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN &&
            (account?.available_credit || account?.available_credit === 0) ? (
              <CreditContainer>
                {account?.available_credit} Credits
              </CreditContainer>
            ) : null}
            {/* Show user popup */}
            <UserPopup />
          </Grid>
        </NavBarComponent>
      </Grid>
      <Grid
        item
        xs={12}
        sm={9}
        md={10}
        sx={{ pt: 3, pb: 3, pl: 2, pr: 2, backgroundColor: catskillWhite }}
      >
        {children}
      </Grid>
    </Grid>
  );
}

export default NavSideBarContainer;
