import React from "react";
import { Spinner } from "../common";

/**
 * This page will be used as a loading screen
 * like, while the app is initializing and user
 * authentication status is being determined
 *
 * TODO: Redesign this page
 */
export default function LoadingPage() {
  return <Spinner />;
}
