import React from "react";
import SVG from "react-inlinesvg";

import { Grid, Button, Typography, Container } from "@mui/material";
import { Box } from "@mui/system";

import {
  NotFoundImageContainer,
  RefreshButton,
  ReportErrorButton,
} from "./components";

import { darkGrey, pickledBlueWoodGrey, goldenColor } from "../library/theme";

import logoImage from "../images/glance-logo.svg";
import { arrowLeftIcon, refreshIcon, flagIcon } from "../images/icons";
import ErrorImage from "../images/error-image.png";

import { Title } from "../common";

export default function ErrorPage() {
  const renderErrorPageImage = () => {
    return (
      <div>
        <Title page="Error page" />
        <NotFoundImageContainer>
          <Box
            component="img"
            src={ErrorImage}
            sx={{ width: "555px", height: "555px" }}
          />
        </NotFoundImageContainer>
      </div>
    );
  };

  return (
    <Container maxWidth="xl">
      <Box
        component={Grid}
        sx={{ minHeight: "100vh" }}
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container item xs={12} md={6} lg={5}>
          <Grid container item alignItems="center" sx={{ pb: 15 }}>
            <Grid item sm={3}>
              <SVG src={logoImage} />
            </Grid>
          </Grid>

          <Typography
            variant="handwriting1"
            sx={{ fontWeight: "bold", color: goldenColor, mb: 5, fontSize: 82 }}
          >
            Oooooooh!
          </Typography>

          <Typography
            variant="h1"
            sx={{
              fontWeight: "normal",
              color: pickledBlueWoodGrey,
              fontSize: "35px",
              mb: 2,
            }}
          >
            This page need a CPR, the code team is on the way
          </Typography>

          <Typography variant="body2" sx={{ mb: 15, color: darkGrey, pl: 1 }}>
            Check your internet connection or firewall and try again
          </Typography>

          <Grid xs={12} container alignItems="center" sx={{ mb: 20 }}>
            <Grid xs={2} item>
              <Button
                startIcon={
                  <Box
                    component={SVG}
                    src={arrowLeftIcon}
                    sx={{ color: "common.black" }}
                  />
                }
                component="a"
                href={process.env.REACT_APP_BASE_URL}
              >
                <Typography variant="body2" sx={{ color: darkGrey }}>
                  Back
                </Typography>
              </Button>
            </Grid>

            <Grid item sx={{ pl: 2 }}>
              <ReportErrorButton
                variant="contained"
                component="a"
                href="https://wa.me/966505655750"
                target="_blank"
                endIcon={<SVG src={flagIcon} />}
              >
                <Typography>Report Error</Typography>
              </ReportErrorButton>
            </Grid>

            <Grid item xs={3} sx={{ pl: 2 }}>
              <RefreshButton
                variant="outlined"
                endIcon={<SVG src={refreshIcon} />}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Typography>Refresh </Typography>
              </RefreshButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          {renderErrorPageImage()}
        </Grid>
      </Box>
    </Container>
  );
}
