import React from "react";
import ReactDOM from "react-dom";
import FullStory from "react-fullstory";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "animate.css";

import App from "./App";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "@mui/material/styles";

import FeatureContextProvider from "./contexts/FeatureContext";
import AuthContextProvider from "./contexts/AuthContext";
import FetchDiseaseDrugsContextProvider from "./contexts/FetchDiseasesContext";
import SummaryContextProvider from "./contexts/SummaryContext";
import ClaimContextProvider from "./contexts/ClaimContext";
import ErrorBoundary from "./error/ErrorBoundary";

import reportWebVitals from "./reportWebVitals";

import theme from "./library/theme";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Client for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <FetchDiseaseDrugsContextProvider>
              <FeatureContextProvider>
                <SummaryContextProvider>
                  <ClaimContextProvider>
                    <App />
                  </ClaimContextProvider>
                </SummaryContextProvider>
              </FeatureContextProvider>
            </FetchDiseaseDrugsContextProvider>
          </AuthContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
    <FullStory org={process.env.REACT_APP_FULLSTORY_ORG_ID} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
