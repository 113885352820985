import jwtDecode from "jwt-decode";

/**
 * Validates email and returns true or false
 *
 * @param  {string} email
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// input mask for otp field
export const otpMask =
  "9              9            9              9               9";

/**
 * Returns the active user ID
 *
 * Checks the user ifo in the tokens, if no token exists returns null
 */
export const getUserId = () => {
  const localStorage = window.localStorage;
  let tokensStr = localStorage.getItem(process.env.REACT_APP_USER_TOKEN);

  if (tokensStr) {
    const tokensObj = JSON.parse(tokensStr);
    const userDetails = jwtDecode(tokensObj.access);
    return userDetails?.user_id;
  }

  return null;
};

export const getAccountId = () => {
  const accountId = localStorage.getItem(process.env.REACT_APP_GROUP_ID);
  if (accountId) {
    return JSON.parse(accountId);
  }

  return null;
};

export const getDiseaseWarningMessages = (messageDetail) => {
  let errorMessage = [];

  if (messageDetail?.is_active === false) {
    errorMessage.push("Icd10 code is inactive and cannot be used anymore.");
  }
  if (messageDetail?.is_covered === false) {
    errorMessage.push("Not covered by the insurance.");
  }
  if (messageDetail?.need_preapproval === true) {
    errorMessage.push("Need pre approval.");
  }

  return errorMessage.length === 1 ? errorMessage[0] : errorMessage;
};

export const toTitleCase = (str) => {
  if (str && typeof str == "string") {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return "";
};

export const isEmpty = (obj) => {
  if (obj) {
    return (
      obj &&
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
    );
  }

  return true;
};

export const roundFileSize = (fileSize) => {
  return `Size: ${
    fileSize > 0
      ? fileSize < 1000000
        ? `${parseFloat(fileSize / 1000)?.toFixed(2)} KB`
        : `${parseFloat(fileSize / 1000000)?.toFixed(2)} MB`
      : `${0} KB`
  }`;
};

export const claimsStatusTypes = {
  STATUS_INIT: "init",
  STATUS_PROCESSING: "processing",
  STATUS_PROCESSED: "processed",
  STATUS_FAILED: "failed",
  STATUS_ERROR: "error",
  STATUS_WARNING: "warning",
  STATUS_SUBMITTED: "submitted",
};

export const resultStatusOptionTypes = {
  STATUS_ERROR: "error",
  STATUS_INFO: "info",
  STATUS_WARNING: "warning",
  STATUS_UPDATE: "update",
  STATUS_TECHNICAL: "technical",
  STATUS_CLINICAL: "clinical",
};
