import api from "../library/api";
import { EVENTS, track } from "../library/analytics";

/**
 * Posts user login credentials and returns a token if success
 * @param  {String} username
 * @param  {String} password
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const authLogin = async (username, password) => {
  try {
    const { status, data } = await api.post("auth/token/", {
      username,
      password,
    });
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response?.status,
      data: null,
      error: response?.data,
    };
  }
};

/**
 * Returns a new set of tokens
 * @param  {String} refresh
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const refreshToken = async (refresh) => {
  try {
    const { status, data } = await api.post("auth/token/refresh/", {
      refresh,
    });
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns current user profile
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const fetchProfile = () => {
  return api.get("auth/profile/");
};

/**
 * Returns token for email verification
 * @param  {String} email
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const sendResetPasswordCode = async (email) => {
  try {
    const { status, data } = await api.post("/auth/password_reset/", {
      email,
    });
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/*
 * Returns token for email verification
 * @param  {number} profileId
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const updateProfile = async (profileId, profileData) => {
  try {
    const { status, data } = await api.put(
      `/auth/profile/${profileId}/update/`,
      {
        ...profileData,
      }
    );
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns Professions Data
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const getProfessions = async () => {
  try {
    const { status, data } = await api.get("/auth/account/profession");
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns Specialties Data
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const getSpecialties = async () => {
  try {
    const { status, data } = await api.get("/auth/account/specialities/");
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns if token is valid or not
 * @param  {String} token
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const validateResetToken = async (token) => {
  try {
    const { status, data } = await api.post(
      "/auth/password_reset/validate_token/",
      {
        token,
      }
    );
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns if password update is successful or not
 * @param  {String} token
 *  @param  {String} password
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const resetPassword = async (token, password) => {
  try {
    const { status, data } = await api.post("auth/password_reset/confirm/", {
      token,
      password,
    });
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns change password
 * @param  {String} oldPassword
 *  @param  {String} newPassword
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */
export const changePassword = async (oldPassword, newPassword) => {
  try {
    const { status, data } = await api.post("auth/change-password/", {
      old_password: oldPassword,
      new_password: newPassword,
    });
    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns a diseases on save
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */

export const saveDisease = async (code) => {
  try {
    const { status, data } = await api.post(`auth/profile/saved-diseases/`, {
      code,
    });
    track(EVENTS.CDS_FAVORITED, { disease: code });

    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns a diseases on delete
 *
 * @returns {String} status - Response status
 * @returns {String} data - Response data if request was success
 * @returns {String} error - Error data if request was failed
 */

export const deleteDisease = async (code) => {
  try {
    const { status, data } = await api.delete(`auth/profile/saved-diseases/`, {
      data: {
        code,
      },
    });

    track(EVENTS.CDS_UNFAVORITED, { disease: code });

    return { status, data, error: null };
  } catch ({ response }) {
    return {
      status: response.status,
      data: null,
      error: response.data,
    };
  }
};

/**
 * Returns recent diseases
 *
 * @returns {String} data - Response data if request was success
 */

export const recentDiseases = () => {
  return api.get(`auth/profile/viewed-diseases/`);
};

/**
 * Returns saved Reports
 *
 * @returns {String} data - Response data if request was success
 */

export const savedReports = () => {
  return api.get(`auth/profile/saved-diseases/`);
};

/**
 * Returns new Announcements
 *
 * @returns {String} data - Response data if request was success
 */

export const getAnnouncementDetails = () => {
  return api.get(`/auth/releases/new/`);
};

/**
 * Returns Announcement list
 *
 * @returns {String} data - Response data if request was success
 */

export const getAnnouncementList = (limit, offsetValue) => {
  return api.get(`/auth/releases/?limit=${limit}&offset=${offsetValue}`);
};

export const getDoctors = async () => {
  const { data } = await api.get(`/auth/doctors/`);
  return data;
};

export const fetchAccountCredit = () => {
  return api.get("auth/profile/credit/");
};
