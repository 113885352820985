import React, { useContext } from "react";
import { LiveChatWidget } from "@livechat/widget-react";

import { AuthContext } from "../contexts";

function ChatWidget() {
  const { user, isLoggedIn } = useContext(AuthContext);

  if (process.env.REACT_APP_LIVECHAT_LICENSE_ID && isLoggedIn && user) {
    return (
      <LiveChatWidget
        license={process.env.REACT_APP_LIVECHAT_LICENSE_ID}
        visibility="minimized"
        customerName={`${user?.first_name} ${user?.last_name}`}
        customerEmail={user?.email}
      />
    );
  }

  return null;
}

export default ChatWidget;
