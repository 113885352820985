import React, { useContext, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";

// Importing all the page components
import { NavSideBarContainer } from "./common";
import LoadingPage from "./auth/LoadingPage";
import NotFoundPage from "./error/404";
// Hooks and contexts
import { AuthContext } from "./contexts";
import { useUrlQuery } from "./hooks";
import ROLES from "./library/roles";
// Importing lazy loaded components
const ForgotPassword = React.lazy(() =>
  import("./auth/UpdatePassword/ForgotPassword")
);
const ChangePassword = React.lazy(() =>
  import("./auth/UpdatePassword/ChangePassword")
);
const ProfileSettings = React.lazy(() => import("./auth/ProfileSettings"));

const SearchResultPage = React.lazy(() => import("./search/SearchResultPage"));
const SearchHomePage = React.lazy(() => import("./search/SearchHomePage"));
const NewClaimPage = React.lazy(() => import("./claims/NewClaim"));
const ClaimsList = React.lazy(() => import("./claims"));
const SupportPage = React.lazy(() => import("./support/SupportPage"));
const SupportFormPage = React.lazy(() => import("./support/SupportFormPage"));
const DoctorsProgress = React.lazy(() => import("./claims/DoctorsProgress"));
const ReviewClaim = React.lazy(() => import("./claims/ReviewClaim"));
const MedicationsPage = React.lazy(() => import("./diagnosis/Medications"));
const SymptomsPage = React.lazy(() => import("./diagnosis/Symptoms"));
const ServicesPage = React.lazy(() => import("./diagnosis/Services"));
const SummaryDetails = React.lazy(() => import("./SummaryDetails"));
const DiagnosisPage = React.lazy(() => import("./diagnosis"));

const LoginPage = React.lazy(() => import("./auth/LoginPage"));

/**
 * A custom route that will allow only authenticated
 * users to see the page.
 *
 * If user is not authenticated, AuthRoute will redirect
 * the user to the login page by adding the original
 * page url as a redirect url as a  query string param
 *
 * @param  {} props - Must have children and path variables within the props
 */
const AuthRoute = (props) => {
  const { roles } = props;
  const auth = useContext(AuthContext);
  const userRole = auth?.user?.profile?.role;

  const { pathname, search } = useLocation();
  let redirectUrl = `/login?redirect=${pathname}`;

  if (search) {
    redirectUrl += `&params=${btoa(search)}`;
  }

  if (userRole && !roles.includes(userRole)) {
    return <Redirect to={"/"} />;
  }

  if (auth.isLoggedIn === true) {
    return (
      <NavSideBarContainer>
        <Route {...props} />
      </NavSideBarContainer>
    );
  } else if (auth.isLoggedIn === false) {
    return <Redirect to={redirectUrl} />;
  } else {
    return <LoadingPage />;
  }
};

/**
 * A custom route that will allow only unauthenticated
 * users to see the page.
 *
 * If the user is not authenticated,
 * UnAuthRoute will show user the requested page
 *
 * If the user is authenticated, UnAuthRoute will redirect
 * the user to the root url ("/") by default.
 * If the original url contains a `_redirect` url query string param
 * UnAuthRoute will redirect the user to that url.
 *
 * @param  {} props - Must have children and path variables within the props
 */
const UnAuthRoute = (props) => {
  let redirectUrl = "/";
  const auth = useContext(AuthContext);
  const { search } = useLocation();
  const query = useUrlQuery();

  if (search) {
    const _redirect = query.get("redirect");
    const _params = query.get("params");

    if (_redirect) {
      redirectUrl = _redirect;
    }
    if (_params) {
      redirectUrl += atob(_params);
    }
  }

  if (auth.isLoggedIn === true) {
    return <Redirect to={redirectUrl} />;
  } else if (auth.isLoggedIn === false) {
    return <Route {...props} />;
  } else {
    return <LoadingPage />;
  }
};

const SearchPage = (props) => {
  const query = useUrlQuery();

  if (query.get("text")) {
    return <SearchResultPage />;
  } else {
    return <SearchHomePage />;
  }
};

export default function RouterComponent() {
  const { user, isAuthLoading } = useContext(AuthContext);

  const userRole = user?.profile?.role;
  if (!user?.profile && isAuthLoading) {
    return <LoadingPage />;
  }

  return (
    <Router>
      <Switch>
        <AuthRoute
          roles={[
            ROLES.ROLE_INSURANCE_ADMIN,
            ROLES.ROLE_USER,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_REVIEWER,
          ]}
          path="/"
          exact={true}
          children={
            userRole === ROLES.ROLE_INSURANCE_ADMIN ||
            userRole === ROLES.ROLE_REVIEWER ? (
              <Suspense fallback={<LoadingPage />}>
                {" "}
                <ClaimsList />
              </Suspense>
            ) : (
              <Suspense fallback={<LoadingPage />}>
                <SearchHomePage />
              </Suspense>
            )
          }
        />
        <AuthRoute
          path="/new-claim/"
          exact={true}
          roles={[ROLES.ROLE_INSURANCE_ADMIN, ROLES.ROLE_USER]}
          children={
            <Suspense fallback={<LoadingPage />}>
              <NewClaimPage />
            </Suspense>
          }
        />
        <AuthRoute
          path="/claims-list/"
          exact={true}
          roles={[
            ROLES.ROLE_INSURANCE_ADMIN,
            ROLES.ROLE_USER,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_REVIEWER,
          ]}
          children={
            <Suspense fallback={<LoadingPage />}>
              <ClaimsList />
            </Suspense>
          }
        />
        <AuthRoute
          path="/search/"
          exact={true}
          roles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN]}
          children={
            <Suspense fallback={<LoadingPage />}>
              <SearchPage />
            </Suspense>
          }
        />

        <AuthRoute
          path="/doctor-progress/"
          exact={true}
          roles={[ROLES.ROLE_INSURANCE_ADMIN, ROLES.ROLE_REVIEWER]}
          children={
            <Suspense fallback={<LoadingPage />}>
              <DoctorsProgress />
            </Suspense>
          }
        />
        <AuthRoute
          path="/diagnosis/:code/medications/"
          roles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN]}
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <MedicationsPage />
            </Suspense>
          }
        />
        <AuthRoute
          path="/diagnosis/:code/symptoms/"
          roles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN]}
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <SymptomsPage />
            </Suspense>
          }
        />
        <AuthRoute
          path="/diagnosis/:code/services/"
          roles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN]}
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <ServicesPage />
            </Suspense>
          }
        />
        <AuthRoute
          path="/diagnosis/:code/"
          roles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN]}
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <DiagnosisPage />
            </Suspense>
          }
        />
        <AuthRoute
          path="/summary/"
          roles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN]}
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <SummaryDetails />
            </Suspense>
          }
        />

        <AuthRoute
          roles={[
            ROLES.ROLE_USER,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_INSURANCE_ADMIN,
          ]}
          exact={true}
          path="/support/send-message"
          children={
            <Suspense fallback={<LoadingPage />}>
              <SupportFormPage />
            </Suspense>
          }
        />
        <AuthRoute
          exact={true}
          roles={[
            ROLES.ROLE_INSURANCE_ADMIN,
            ROLES.ROLE_USER,
            ROLES.ROLE_REVIEWER,
          ]}
          path="/claim/:id/"
          children={
            <Suspense fallback={<LoadingPage />}>
              <ReviewClaim />
            </Suspense>
          }
        />
        <AuthRoute
          exact={true}
          path="/support"
          roles={[
            ROLES.ROLE_USER,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_INSURANCE_ADMIN,
          ]}
          children={
            <Suspense fallback={<LoadingPage />}>
              <SupportPage />
            </Suspense>
          }
        />
        <AuthRoute
          path="/change-password"
          roles={[
            ROLES.ROLE_USER,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_INSURANCE_ADMIN,
            ROLES.ROLE_REVIEWER,
          ]}
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <ChangePassword />
            </Suspense>
          }
        />
        <AuthRoute
          path="/profile-settings"
          roles={[
            ROLES.ROLE_USER,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_INSURANCE_ADMIN,
            ROLES.ROLE_REVIEWER,
          ]}
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <ProfileSettings />
            </Suspense>
          }
        />
        <UnAuthRoute
          path="/login"
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <LoginPage />
            </Suspense>
          }
        />
        <UnAuthRoute
          path="/forgot-password"
          exact={true}
          children={
            <Suspense fallback={<LoadingPage />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route path="*" children={<NotFoundPage />} />
      </Switch>
    </Router>
  );
}
