import React, { useContext, useState } from "react";
import SVG from "react-inlinesvg";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

import {
  Avatar,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  ListItemIcon,
  Divider,
  Typography,
} from "@mui/material";

import {
  envelopIcon,
  keyOutlined,
  doorIcon,
  personIcon,
  walletIcon,
  settingsIcon,
} from "../../images/icons";

import { red as redColor, pickledBlueWoodGrey } from "../../library/theme";

import { ProfileMenu, ItemTextWrapper } from "./components";
import ROLES from "../../library/roles";

// Context
import { AuthContext } from "../../contexts";

function UserPopup() {
  const { user, logout, refreshAcccountCredit } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    refreshAcccountCredit();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderUserPopupMenu = () => {
    return (
      <ProfileMenu
        id="user-popup-user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby="user-popup-user-button"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: -10,
            overflow: "visible",
            boxShadow:
              "0px 1px 100px 0px rgb(0 46 120 / 13%), 0px 3px 20px 0px rgb(0 0 0 / 10%)",
          },
        }}
      >
        <MenuItem sx={{ padding: "20px" }} key="user-popup-user-name">
          <ListItemIcon sx={{ pb: 4 }}>
            <Box
              component={SVG}
              sx={{
                width: 25,
                height: 25,
                color: pickledBlueWoodGrey,
                pr: 2,
              }}
              src={personIcon}
            />
          </ListItemIcon>

          <ListItemText
            primary={
              <ItemTextWrapper variant="body1" component={Typography}>
                {user?.profile?.account?.[0]?.name}
              </ItemTextWrapper>
            }
            secondary={[
              <Typography variant="body2">
                Branch {user?.profile?.account?.[0]?.branch_name}
              </Typography>,
              <Typography variant="body2">
                {user?.profile?.account?.[0]?.city?.name}
              </Typography>,
            ]}
          />
        </MenuItem>
        <Divider key="user-popup-divider-1" />
        {user?.profile?.role === ROLES.ROLE_INSURANCE_ADMIN && [
          <MenuItem sx={{ padding: "20px" }} key="user-popup-user-credit">
            <ListItemIcon>
              <Box
                component={SVG}
                sx={{ color: pickledBlueWoodGrey, pr: 2 }}
                src={walletIcon}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <ItemTextWrapper variant="body1" component={Typography}>
                  Credit : {user?.profile?.account?.[0]?.available_credit}
                </ItemTextWrapper>
              }
              secondary={
                user?.profile?.account?.[0]?.available_credit < 1000 && (
                  <Typography
                    variant="body1"
                    component={Typography}
                    sx={{ color: redColor }}
                  >
                    Low Credit
                  </Typography>
                )
              }
            />
          </MenuItem>,
          <Divider key="user-popup-divider-2" />,
        ]}
        <MenuItem sx={{ padding: "20px" }} key="user-popup-user-email">
          <ListItemIcon>
            <Box
              component={SVG}
              sx={{ color: pickledBlueWoodGrey, pr: 2 }}
              src={envelopIcon}
            />
          </ListItemIcon>
          <ListItemText>
            <ItemTextWrapper variant="body1" component={Typography}>
              {user.email}
            </ItemTextWrapper>
          </ListItemText>
        </MenuItem>
        <Divider key="user-popup-divider-3" />
        <MenuItem
          component={Link}
          to={`/profile-settings?email=${user?.email}&redirect=/`}
          sx={{ padding: "20px" }}
          key="user-popup-user-change-password"
          onClick={handleClose}
        >
          <ListItemIcon>
            <Box
              component={SVG}
              sx={{
                color: pickledBlueWoodGrey,
                height: 20,
                width: 20,
              }}
              src={settingsIcon}
            />
          </ListItemIcon>
          <ListItemText>
            <ItemTextWrapper variant="body1" component={Typography}>
              Profile Settings
            </ItemTextWrapper>
          </ListItemText>
        </MenuItem>
        <Divider key="user-popup-divider-4" />
        <MenuItem
          component={Link}
          to={`/change-password?email=${user?.email}&redirect=/`}
          sx={{ padding: "20px" }}
          key="user-popup-user-change-password"
          onClick={handleClose}
        >
          <ListItemIcon>
            <Box
              component={SVG}
              sx={{
                color: pickledBlueWoodGrey,
                height: 20,
                width: 20,
              }}
              src={keyOutlined}
            />
          </ListItemIcon>
          <ListItemText>
            <ItemTextWrapper variant="body1" component={Typography}>
              Change passcode
            </ItemTextWrapper>
          </ListItemText>
        </MenuItem>
        <Divider key="user-popup-divider-4" />
        <MenuItem
          sx={{ padding: "20px" }}
          onClick={() => {
            logout();
            handleClose();
          }}
          key="user-popup-user-logout"
        >
          <ListItemIcon>
            <Box
              component={SVG}
              sx={{
                color: redColor,
                height: 20,
                width: 20,
              }}
              src={doorIcon}
            />
          </ListItemIcon>
          <ListItemText>
            <ItemTextWrapper variant="body1" component={Typography}>
              Sign Out
            </ItemTextWrapper>
          </ListItemText>
        </MenuItem>
      </ProfileMenu>
    );
  };

  if (!!user) {
    return (
      <div>
        <List>
          <ListItemButton
            onClick={handleClick}
            id="user-popup-user-button"
            aria-controls={open ? "user-popup-user-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                sx={{ bgcolor: "primary.main" }}
                alt={user.first_name}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${user.first_name} ${user.last_name}`}
              secondary={user?.profile?.account?.[0]?.name}
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            />
          </ListItemButton>
        </List>

        {renderUserPopupMenu()}
      </div>
    );
  }

  return null;
}

export default UserPopup;
