import React, { useContext, useState } from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";

import {
  Badge,
  List,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from "@mui/material";

import { NavListItem } from "./components";

import AnnouncementDrawer from "../AnnouncementDrawer";

import { getAnnouncementDetails } from "../../services/auth";

// Context
import { AuthContext, SummaryContext } from "../../contexts";

// Icons
import {
  campaignOutlineRounded,
  chatOutlineRounded,
  createNewFolderOutline,
  listBulletedOutline,
  chartOutlineRounded,
  searchRoundedIcon,
  clipboardFill,
} from "../../images/icons";

import ROLES from "../../library/roles";

function Navigation() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showNewAnnouncement, setShowNewAnnouncement] = useState(true);
  const { user } = useContext(AuthContext);
  const { count: summaryCount } = useContext(SummaryContext);

  const { data: newAnnouncement } =
    useQuery(["new-announcement"], () => getAnnouncementDetails(), {
      staleTime: 1000 * 60 * 60 * 24,
    }) || {};

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const claimsMenuItems = [
    {
      icon: createNewFolderOutline,
      text:
        user?.profile?.role === ROLES.ROLE_USER
          ? "Approval Checkup"
          : "New Claim",
      link: "/new-claim",
      roles: [ROLES.ROLE_USER, ROLES.ROLE_INSURANCE_ADMIN],
    },
    {
      icon: listBulletedOutline,
      text: "All Claims",
      link: "/claims-list/",
      roles: [
        ROLES.ROLE_USER,
        ROLES.ROLE_INSURANCE_ADMIN,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_REVIEWER,
      ],
    },
    {
      icon: chartOutlineRounded,
      text: "Rework Progress",
      link: "/doctor-progress/",
      roles: [
        ROLES.ROLE_INSURANCE_ADMIN,
        ROLES.ROLE_REVIEWER,
        ROLES.ROLE_ADMIN,
      ],
    },
  ];

  const supportMenuItems = [
    {
      icon: chatOutlineRounded,
      text: "Help",
      link: "/support",
      roles: [
        ROLES.ROLE_USER,
        ROLES.ROLE_INSURANCE_ADMIN,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_REVIEWER,
      ],
    },
    {
      icon: campaignOutlineRounded,
      text: "What's new?",
      link: "/whats-new",
      onClick: () => {
        handleDrawerOpen();
        setShowNewAnnouncement(false);
      },
      roles: [
        ROLES.ROLE_USER,
        ROLES.ROLE_INSURANCE_ADMIN,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_REVIEWER,
      ],
    },
  ];

  const cdsMenuItems = [
    {
      icon: searchRoundedIcon,
      text: "Search",
      link: "/search",
      roles: [ROLES.ROLE_USER, ROLES.ROLE_ADMIN],
    },
    {
      icon: clipboardFill,
      text: "Summary",
      link: "/summary",
      roles: [ROLES.ROLE_USER, ROLES.ROLE_ADMIN],
      count: summaryCount,
    },
  ];

  const renderMenuDetails = (item, showBadge) => (
    <>
      <ListItemIcon sx={{ color: "inherit", minWidth: 32 }}>
        <SVG src={item.icon} />
      </ListItemIcon>
      <ListItemText
        secondaryTypographyProps={{
          sx: {
            color: "inherit",
          },
        }}
        secondary={item.text}
      />
      {(!!showBadge || !!item?.count) && (
        <Badge
          color="primary"
          variant={item?.count ? "standard" : "dot"}
          badgeContent={item?.count}
        />
      )}
    </>
  );

  const renderMenu = (subheader, menuItems) => (
    <List
      sx={{
        mt: 1,
      }}
      subheader={<ListSubheader sx={{ pl: 0 }}>{subheader}</ListSubheader>}
    >
      {menuItems?.map((item, key) => {
        if (!item.roles.includes(user?.profile?.role)) {
          return null;
        }

        if (item?.onClick) {
          return (
            <NavListItem
              key={`${subheader}-menu-items-${key}`}
              onClick={item.onClick}
            >
              {renderMenuDetails(
                item,
                showNewAnnouncement && newAnnouncement?.data?.count
              )}
            </NavListItem>
          );
        }

        return (
          <NavListItem
            key={`${subheader}-menu-items-${key}`}
            component={NavLink}
            to={item.link}
            end="true"
          >
            {renderMenuDetails(item, false)}
          </NavListItem>
        );
      })}
    </List>
  );

  return (
    <>
      <AnnouncementDrawer
        open={drawerOpen}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
      />
      {[ROLES.ROLE_USER, ROLES.ROLE_ADMIN].includes(user?.profile?.role) &&
        renderMenu("Library", cdsMenuItems)}
      {renderMenu("Claims", claimsMenuItems)}
      {renderMenu("Support", supportMenuItems)}
    </>
  );
}

export default Navigation;
