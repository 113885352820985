import { styled } from "@mui/system";
import { Grid, Menu, Box, ListItemButton, Typography } from "@mui/material";

import { pickledBlueWoodGrey, catskillWhite } from "../../library/theme";

export const NavBarComponent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  position: "sticky",
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: theme.palette.common.white,
  height: "100vh",
  justifyContent: "space-between",
  flexDirection: "column",
}));

export const ProfileMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: 15,
  },
  "& .MuiPopover-paper::before": {
    content: "''",
    dsiplay: "block",
    position: "absolute",
    borderColor: "white transparent",
    borderStyle: "solid",
    borderWidth: "10px 16px 0px 16px",
    width: 0,
    height: 0,
    bottom: -10,
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

export const ItemTextWrapper = styled(Box)(() => ({
  fontWeight: "600",
  color: pickledBlueWoodGrey,
  fontSize: "14px",
  lineHeight: "21px",
}));

export const NavListItem = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  borderRadius: 8,
  marginBottom: theme.spacing(1),
  "&.active": {
    color: theme.palette.primary.main,
    backgroundColor: catskillWhite,
  },
}));

export const CreditContainer = styled(Typography)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: catskillWhite,
  padding: theme.spacing(1),
  width: "calc(100% - 16px)",
  textAlign: "center",
  marginTop: theme.spacing(1),
  fontSize: "0.8em",
  fontWeight: 500,
}));
