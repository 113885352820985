import axios from "axios";
import jwt_decode from "jwt-decode";
import JSAlert from 'js-alert'

let refreshTokenRequestPromise = null;
let isRefreshTokenRequestActive = false;
const localStorage = window.localStorage;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/**
 * @name Logout
 *
 * Log out the user by clearing token from localstorage
 * and reload the window
 */
const logout = () => {
  localStorage.removeItem(process.env.REACT_APP_USER_TOKEN);
  window.location.reload();
};

/**
 * Add request to a queue to process later
 * If a request for refresh token is active,
 * wait for this request to finish and use the
 * received set of tokens to proceed with
 * the new request
 *
 * @param  {Object} config
 *
 * @returns {Promise} config
 */
const addRequestToQueue = async (config) => {
  return new Promise(async (resolve) => {
    const result = await refreshTokenRequestPromise();
    const accessToken = result.data.access;
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return resolve(config);
  });
};

/**
 * Checks if the given token is active or not
 * by checking the expiry date
 *
 * @param  {String} accessToken - JWT access token
 */

const isTokenActive = (token) => {
  const { exp: tokenExpiryDate } = jwt_decode(token);
  const currentDateTime = new Date();
  const expDateTime = new Date(0);
  expDateTime.setUTCSeconds(tokenExpiryDate);

  return expDateTime > currentDateTime;
};

/**
 * Make API call to get new token
 *
 * if refreshTokenRequestPromise is null,
 * that means, no active request for refresh token exists,
 * make a request to fetch new set of tokens
 *
 * logs out the user if refresh token is expired
 *
 * log out the user if no refresh token found
 * in localstorage or the request for new token failed,
 * (that could mean the refresh token is expired)
 */
const requestNewTokens = async () => {
  if (refreshTokenRequestPromise) {
    return refreshTokenRequestPromise;
  }

  try {
    isRefreshTokenRequestActive = true;
    const tokensString = localStorage.getItem(process.env.REACT_APP_USER_TOKEN);
    if (tokensString) {
      const tokens = JSON.parse(tokensString);
      if (isTokenActive(tokens.refresh)) {
        refreshTokenRequestPromise = await api.post("auth/token/refresh/", {
          refresh: tokens.refresh,
        });

        return refreshTokenRequestPromise;
      }
    }

    logout();
  } catch (error) {
    if (error) {
      logout();
    }
  }
};

/**
 * Update the tokens
 *
 * Update tokens by calling the refresh token api
 * and store the new token on localstorage
 * and proceed with the original request
 *
 * @param {*} originalRequestConfig - Configurations of original request
 */
const updateTokens = async (originalRequestConfig) => {
  originalRequestConfig._retry = true;
  const { data: tokens } = await requestNewTokens();

  localStorage.setItem(
    process.env.REACT_APP_USER_TOKEN,
    JSON.stringify(tokens)
  );
  isRefreshTokenRequestActive = false;

  originalRequestConfig.headers.Authorization = `Bearer ${tokens.access}`;

  return api(originalRequestConfig);
};

api.interceptors.request.use(
  /**
   * if request for refresh token already in progress.
   * Add the request to a promise queue to execute later
   *
   * check if token expired,
   * if expired, update token & inform the auth context
   *
   * else, Add the access token to the config header
   */
  async (config) => {
    const path = config.url
      .split("/")
      .filter((value) => !!value)
      .pop();

    if (
      isRefreshTokenRequestActive &&
      refreshTokenRequestPromise &&
      path !== "refresh"
    ) {
      return addRequestToQueue();
    } else {
      if (path !== "refresh") {
        const tokensString = localStorage.getItem(
          process.env.REACT_APP_USER_TOKEN
        );
        if (tokensString) {
          const tokens = JSON.parse(tokensString);
          if (isTokenActive(tokens.access)) {
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${tokens.access}`,
            };
          } else {
            return updateTokens(config);
          }
        }
      }

      return config;
    }
  },
  (error) => {
    if (error) {
      return Promise.reject(error);
    }
  }
);

api.interceptors.response.use(
  /**
   * If there is a refreshTokenRequestPromise exists
   * and the api already returned a successful response,
   * it means the api already cleared the refresh token issue.
   * so we can clear the refreshTokenRequestPromise to null
   *
   * @param {*} resposne - API response
   */
  (resposne) => {
    if (refreshTokenRequestPromise) {
      refreshTokenRequestPromise = null;
    }

    return resposne;
  },
  /**
   * When the error failed and the error status
   * is 403 (Forbidden), the most probable chance
   * is the access token is expired. In that case,
   * request for a new set of tokens by using
   * available refresh token
   *
   * Once the function receives new access token,
   * continue calling the original request with
   * the new authorization token header
   *
   * If refresh token is expired, logout the user
   *
   *
   * @param {*} error - Error response from the api call
   */
  async (error) => {
    const originalRequest = error?.config;
    const tokensString = localStorage.getItem(process.env.REACT_APP_USER_TOKEN);
    if (
      error?.response?.status === 403 &&
      tokensString &&
      !originalRequest._retry
    ) {
      return updateTokens(originalRequest);
    } else if (error?.response?.status === 429) {
      const message = "Usage limit exceeded. Please try again later.";
      JSAlert.alert(`Error: ${message}`).dismissIn(10000);
    }

    return Promise.reject(error);
  }
);

export default api;
