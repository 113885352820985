import { Button } from "@mui/material";
import { styled } from "@mui/system";
import {
  darkGrey,
  emptyStateGrey,
  red,
  reportRed,
  grey,
  blue,
} from "../library/theme";

export const NotFoundImageContainer = styled("div")({
  marginTop: 20,
  textAlign: "center",
  svg: {
    maxWidth: "100%",
  },
});

export const GotoButton = styled(Button)(() => ({
  color: darkGrey,
  "&:hover": {
    color: blue,
    background: "white",
  },
}));

export const RefreshButton = styled(Button)(() => ({
  width: "198px",
  height: "56px",
  display: "flex",
  justifyContent: "space-between",
  Padding: "20px 24px",
  color: grey,
  borderColor: emptyStateGrey,
}));

export const ReportErrorButton = styled(Button)(() => ({
  width: "227px",
  height: "56px",
  display: "flex",
  justifyContent: "space-between",
  Padding: "20px 24px",
  color: "white",
  background: `linear-gradient(180deg, ${red} 0%, ${reportRed} 100%)`,
  boxShadow: "0px 4px 10px rgba(251, 113, 133, 0.5)",
  "&:hover": {
    background: `linear-gradient(180deg, ${red} 0%, ${reportRed} 100%)`,
  },
}));
