import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { omit, groupBy } from "lodash";
import { useQuery } from "react-query";

import { getDoctors } from "../services";

import { AuthContext } from "./AuthContext";

const defaultValues = {
  hasSelections: false,
  selectionCount: 0,
  doctors: {},
};

export const ClaimContext = createContext(defaultValues);

export default function ClaimContextProvider({ children }) {
  const claimsPaginationOptions = [10, 20, 50, 100];

  const { isLoggedIn } = useContext(AuthContext);

  const [claimFileId, setClaimFileId] = useState(false);
  const [selectedClaims, setClaimSelection] = useState({});
  const [hasSelections, setHasSelections] = useState(false);
  const [selectionCount, setSelectionCount] = useState(false);
  const [allClaimSelected, setAllClaimsSelected] = useState(false);
  const [isClaimAssignmentDialogOpen, setClaimAssignmentDialogOpen] =
    useState(false);
  const [assignmentMode, setAssignmentMode] = useState("multiple");
  const [isAssign, setIsAssign] = useState(false);
  const [claimListPaginationLimit, setClaimListPaginationLimit] = useState(
    claimsPaginationOptions[1]
  );
  const [activeClaim, setActiveClaim] = useState();
  const [allClaims, setAllClaims] = useState(null);

  const { data: doctors } = useQuery(["account-doctors"], () => getDoctors(), {
    enabled: isLoggedIn,
  });

  const getKey = (claim) => {
    return `${claim.claimfile_id}_${claim.claim_id}`;
  };

  useEffect(() => {
    const _selectionCount = Object.keys(selectedClaims).length;
    setSelectionCount(_selectionCount);
    setHasSelections(_selectionCount > 0);
  }, [selectedClaims]);

  const isSelected = (claim) => {
    return Boolean(selectedClaims[getKey(claim)]);
  };

  const select = (claim) => {
    setClaimSelection((state) => {
      return {
        ...state,
        [getKey(claim)]: claim,
      };
    });
  };

  const unSelect = (claim) => {
    setClaimSelection(omit(selectedClaims, getKey(claim)));
  };

  const toggleSelection = (claim) => {
    if (!isSelected(claim)) {
      select(claim);
    } else {
      unSelect(claim);
    }
  };

  const setClaim = (id) => {
    if (id) {
      setClaimFileId(id);
    }
  };

  const handleSetActiveClaim = (claim) => {
    // To update state properly when we add revisedResult
    if (claim && !claim?.revised_result) {
      claim.revised_result = null;
    }
    setActiveClaim(claim);
  };

  const clearSelection = useCallback(() => {
    setClaimSelection({});
    setAllClaimsSelected(false);
  }, []);

  const closeAssignmentDialog = () => {
    setClaimAssignmentDialogOpen(false);
    setAssignmentMode("multiple");
  };

  const openAssignmentDialog = (mode, assign = true) => {
    setClaimAssignmentDialogOpen(true);
    setAssignmentMode(mode);
    setIsAssign(assign);
  };

  const getSelectedClaimsGroupedByDoctor = useMemo(() => {
    if (!hasSelections) {
      return false;
    }

    return groupBy(Object.values(selectedClaims), ({ doctor_id }) => doctor_id);
  }, [hasSelections, selectedClaims]);

  const selectedAssignedClaims = useMemo(() => {
    const _selectedClaims = getSelectedClaimsGroupedByDoctor;

    return (
      _selectedClaims &&
      Object.keys(_selectedClaims)
        .map((doctorId) =>
          _selectedClaims[doctorId].filter((claim) => !!claim?.assignee_id)
        )
        ?.flat(1)
        ?.map((claim) => claim?.claim_id)
        ?.join(",")
    );
  }, [getSelectedClaimsGroupedByDoctor]);

  return (
    <ClaimContext.Provider
      value={{
        select,
        unSelect,
        isSelected,
        isAssign,
        toggleSelection,
        clearSelection,
        hasSelections,
        selectionCount,
        claimFileId,
        setClaim,
        doctors,
        viewAssignmentDialog: isClaimAssignmentDialogOpen,
        closeAssignmentDialog,
        openAssignmentDialog,
        assignmentMode,
        selectedAssignedClaims,
        setAllClaimsSelected,
        allClaimSelected,
        selectedClaims: getSelectedClaimsGroupedByDoctor,
        claimsPaginationOptions,
        claimListPaginationLimit,
        setClaimListPaginationLimit,
        activeClaim,
        setActiveClaim: handleSetActiveClaim,
        allClaims,
        setAllClaims,
      }}
    >
      {children}
    </ClaimContext.Provider>
  );
}
