import React, { createContext, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { fetchFeatureFlags } from "../services";

import { AuthContext } from "./AuthContext";

/*
Default context values will be used
if the context is loaded isolated
Eg: in unit test
*/
const defaultValue = {
  flags: {},
  samples: {},
  switches: {},
};

/**
 * Feature Context with default values
 * Assigning default values will be only accessible
 * if this context is used as isolated
 * from the contect provider
 *
 * @returns {Object} flags -Flags can be used to enable a feature
 * for specific users, groups, users meeting certain criteria
 * (such as being authenticated, or superusers)
 * or a certain percentage of visitors.
 * @returns {Object} samples - Samples are on a given percentage of the time
 * @returns {Object} switches - Switches are simple booleans:
 * they are on or off, for everyone, all the time
 * @returns {Function} reloadFeatureFlags - A function to load feature flags manually
 *
 */
export const FeatureContext = createContext(defaultValue);

/**
 * Feature context provider
 * This function will be responsible to call the feature api
 * Feature api will be called while initializing the provider
 * and pass the feature flag values as a context data
 *
 * @param  {} {children}
 *
 */
export default function FeatureContextProvider({ children }) {
  const {
    data,
    refetch: reloadFeatureFlags,
    isRefetching,
  } = useQuery("features", fetchFeatureFlags); // Calling the api
  const value = data || defaultValue; // Get the value else use default value
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn !== null && reloadFeatureFlags) {
      reloadFeatureFlags();
    }
  }, [isLoggedIn, reloadFeatureFlags]);

  return (
    <FeatureContext.Provider value={value}>
      {isRefetching ? "Re fetching now" : ""}
      {children}
    </FeatureContext.Provider>
  );
}
