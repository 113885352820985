import React, { useState } from "react";
import SVG from "react-inlinesvg";
import Moment from "react-moment";
import { useQuery } from "react-query";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Box, Button, Drawer, Grid, Typography } from "@mui/material";

import { arrowLeftIcon } from "../../images/icons";

import { getAnnouncementList } from "../../services/auth";

import {
  lightBlue,
  grey,
  emptyStateGrey,
  darkGreyColor,
} from "../../library/theme";

export default function AnnouncementDrawer({ open, onClose }) {
  const [selectedNote, setSelectedNote] = useState();

  const [offsetValue, setOffsetValue] = useState(0);

  const { data: announcementList } =
    useQuery(
      ["announcement-list", offsetValue, open],
      () => getAnnouncementList(5, offsetValue),
      { keepPreviousData: true, enabled: open }
    ) || {};

  const handleMarkDown = (markdown) => (
    <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
  );

  const handleReadMore = (text, index) => {
    return (
      <>
        <Typography variant="body1">
          {selectedNote === index
            ? handleMarkDown(text)
            : text?.length < 100
            ? handleMarkDown(text)
            : handleMarkDown(text?.slice(0, 150))}
          <Box
            component="span"
            sx={{ color: grey, cursor: "pointer" }}
            onClick={() => {
              selectedNote === index
                ? setSelectedNote()
                : setSelectedNote(index);
            }}
          >
            {selectedNote === index ? "Read less" : "Read all"}
          </Box>
        </Typography>
      </>
    );
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: "340px", padding: "20px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              color="error"
              startIcon={
                <Box
                  component={SVG}
                  src={arrowLeftIcon}
                  sx={{ color: "common.black" }}
                />
              }
              sx={{ mb: 4 }}
              onClick={onClose}
            >
              Get back
            </Button>
          </Grid>
          <Grid item>
            <Typography
              variant="handwriting3"
              sx={{ color: lightBlue, fontSize: "24px" }}
            >
              Whats new in Glance!
            </Typography>
          </Grid>
          {announcementList?.data?.results?.map((announcementDetail, index) => {
            return (
              <Grid item sx={{ pt: 5 }}>
                <Typography variant="body2" sx={{ pb: 1 }}>
                  <Moment fromNow>{announcementDetail?.date}</Moment>
                </Typography>

                {handleReadMore(announcementDetail?.body, index)}
              </Grid>
            );
          })}
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 5 }}
          >
            <Grid item>
              {announcementList?.data?.next && (
                <Button
                  variant="outlined"
                  sx={{ borderColor: emptyStateGrey }}
                  onClick={() => {
                    setOffsetValue((offset) => offset + 5);
                  }}
                >
                  <Typography variant="body2" sx={{ darkGreyColor }}>
                    Show more
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
